<template>
  <authentication-card v-if="!isFromTeams">
    <template #logo>
      <authentication-card-logo />
    </template>

    <div
      v-if="status"
      class="mb-4 text-sm text-green-600"
    >
      {{ status }}
    </div>

    <div>
      <p
        tabindex="0"
        role="heading"
        aria-label="Login to your account"
        class="text-2xl font-extrabold leading-6"
      >
        {{ t('account.login') }}
      </p>
      <div>
        <a :href="googleRedirectLink">
          <button
            :aria-label="t('account.log_with_google')"
            role="button"
            class="focus:outline-hidden focus:ring-2 focus:ring-custom-blue-light py-3.5 px-4 border rounded-lg border-gray-200 hover:border-custom-blue flex items-center w-full mt-10"
          >
            <svg
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.9892 10.1871C18.9892 9.36767 18.9246 8.76973 18.7847 8.14966H9.68848V11.848H15.0277C14.9201 12.767 14.3388 14.1512 13.047 15.0812L13.0289 15.205L15.905 17.4969L16.1042 17.5173C17.9342 15.7789 18.9892 13.221 18.9892 10.1871Z"
                fill="#4285F4"
              />
              <path
                d="M9.68813 19.9314C12.3039 19.9314 14.4999 19.0455 16.1039 17.5174L13.0467 15.0813C12.2286 15.6682 11.1306 16.0779 9.68813 16.0779C7.12612 16.0779 4.95165 14.3395 4.17651 11.9366L4.06289 11.9465L1.07231 14.3273L1.0332 14.4391C2.62638 17.6946 5.89889 19.9314 9.68813 19.9314Z"
                fill="#34A853"
              />
              <path
                d="M4.17667 11.9366C3.97215 11.3165 3.85378 10.6521 3.85378 9.96562C3.85378 9.27905 3.97215 8.6147 4.16591 7.99463L4.1605 7.86257L1.13246 5.44363L1.03339 5.49211C0.37677 6.84302 0 8.36005 0 9.96562C0 11.5712 0.37677 13.0881 1.03339 14.4391L4.17667 11.9366Z"
                fill="#FBBC05"
              />
              <path
                d="M9.68807 3.85336C11.5073 3.85336 12.7344 4.66168 13.4342 5.33718L16.1684 2.59107C14.4892 0.985496 12.3039 0 9.68807 0C5.89885 0 2.62637 2.23672 1.0332 5.49214L4.16573 7.99466C4.95162 5.59183 7.12608 3.85336 9.68807 3.85336Z"
                fill="#EB4335"
              />
            </svg>
            <p class="text-base font-medium ml-4">
              {{ t('account.log_with_google') }}
            </p>
          </button>
        </a>
        <a :href="azureRedirectLink">
          <button
            :aria-label="t('account.log_with_microsoft')"
            role="button"
            class="focus:outline-hidden focus:ring-2 focus:ring-custom-blue-light py-3.5 px-4 border rounded-lg border-gray-200 hover:border-custom-blue flex items-center w-full mt-4"
          >
            <svg
              class="h-7 w-7"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 23 23"
            >
              <path
                fill="#f3f3f3"
                d="M0 0h23v23H0z"
              />
              <path
                fill="#f35325"
                d="M1 1h10v10H1z"
              />
              <path
                fill="#81bc06"
                d="M12 1h10v10H12z"
              />
              <path
                fill="#05a6f0"
                d="M1 12h10v10H1z"
              />
              <path
                fill="#ffba08"
                d="M12 12h10v10H12z"
              />
            </svg>
            <p class="text-base font-medium ml-4">
              {{ t('account.log_with_microsoft') }}
            </p>
          </button>
        </a>
        <section-border :word="t('layouts.words.or')" />
      </div>
      <d-form
        class-custom="flex flex-col gap-6"
        @submit.prevent="submit"
      >
        <d-input
          v-model="form.email"
          :disabled="email !== null"
          placeholder="example@yourcompany.com"
          :error="form.errors.email"
          :autofocus="email == null"
          autocomplete="email"
        />
        <d-input
          v-if="email"
          v-model="form.password"
          password
          placeholder="Password"
          :error="form.errors.password"
          :autofocus="true"
          autocomplete="current-password"
        />
        <el-form-item>
          <d-button
            class="w-full"
            :loading="form.processing"
            :disabled="form.processing"
            native-type="submit"
            @click="submit"
          >
            {{ t('layouts.words.login') }}
          </d-button>
        </el-form-item>
      </d-form>
      <div class="flex justify-end mt-4">
        <Link
          v-if="canResetPassword"
          :href="route('password.request')"
          class="underline text-sm"
        >
          {{ t('account.password_forgot') }}
        </Link>
      </div>
    </div>
  </authentication-card>
</template>

<script setup lang="ts">
import { router, useForm } from '@inertiajs/vue3'
import DInput from '@/Components/ui/DInput.vue'
import DButton from '@/Components/ui/DButton.vue'
import AuthenticationCard from '@/Components/ui/AuthenticationCard.vue'
import AuthenticationCardLogo from '@/Components/ui/AuthenticationCardLogo.vue'
import SectionBorder from '@/Components/ui/SectionBorder.vue'
import DForm from '@/Components/ui/DForm.vue'
import { route } from 'ziggy-js'
import { computed, onBeforeMount } from 'vue'
import { t } from '@/i18n'

const {
  canResetPassword = false,
  email = null,
  status = undefined
} = defineProps<{
  canResetPassword: boolean
  status?: string
  email: string | null
}>()

const isEmbeddedByMsTeamsNative = navigator.userAgent.toLocaleLowerCase().includes('electron')

const isFromTeams =
  isEmbeddedByMsTeamsNative ||
  window.name === 'embedded-page-container' ||
  window.name === 'extension-tab-frame'

const form = useForm({
  email: email ?? '',
  password: '',
  remember: true
})

const googleRedirectLink = computed(() => {
  return route('auth.google', {
    target: new URL(window.location.href).searchParams.get('target')
  })
})

const azureRedirectLink = computed(() => {
  return route('auth.azure', {
    target: new URL(window.location.href).searchParams.get('target')
  })
})

onBeforeMount(() => {
  if (isFromTeams) {
    router.visit(route('login-jwt-get'))
  }
})

const submit = () => {
  if (email) {
    form
      .transform((data) => ({
        ...data,
        email: form.email.toLowerCase(),
        remember: form.remember ? 'on' : ''
      }))
      .post(route('login'), {
        onFinish: async () => {
          form.reset('password')
        }
      })
  } else {
    form
      .transform(() => ({
        email: form.email.toLowerCase()
      }))
      .post(route('login-redirect'), {
        preserveScroll: true,
        onSuccess: () => {}
      })
  }
}
</script>
